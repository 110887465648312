//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { billcustomer } from '@/api/login';
import { updateSource } from '@/api/updateUserInfo/Journal';
export default {
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      value1: null,
      title: '学员来源修改',
      tableData: [],
      optionsvalue: '',
      mobile: '',
      source_client_id: '',
      //花名id
      flower_Array: [],
      //花名
      id: ''
    };
  },
  components: {
    Head: Head
  },
  mounted: function mounted() {
    if (this.course == 1) {} else {
      this.flowerArray(); //花名
    }
  },
  methods: {
    flowerArray: function flowerArray() {
      var _this = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this.flower_Array = res.data;
      });
    },
    //查询
    hitTheBill: function hitTheBill() {
      var _this2 = this;
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$notify({
          title: '警告',
          message: '请输入正确手机号',
          type: 'warning',
          duration: 2000
        });
        this.Show = false;
      } else if (this.mobile == '') {
        this.$notify({
          title: '警告',
          message: '请输入手机号',
          type: 'warning',
          duration: 2000
        });
      } else {
        this.tableData = [];
        billcustomer({
          mobile: this.mobile
        }).then(function (respomse) {
          if (respomse.data !== '') {
            var sourceData = respomse.data;
            _this2.tableData = sourceData;
            _this2.source_client_id = sourceData[0].source_client_id;
            _this2.id = sourceData[0].cid;
          }
        });
      }
    },
    //学员来源修改
    flowerArrayEs: function flowerArrayEs(val) {
      var _this3 = this;
      updateSource({
        id: this.id,
        source_client_id: this.source_client_id
      }).then(function (res) {
        if (res.code == 0) {
          _this3.hitTheBill();
          _this3.$message({
            message: '编辑成功!',
            type: 'success'
          });
        }
      });
    }
  }
};